<template>
    <div class="px-2">
        <div v-if="instanceLogos && instanceLogos.length">
            <template v-for="(inst,instI) in instanceLogos">

                <a-collapse expandIconPosition="right" :key="instI" class="mb-2">

                    <a-collapse-panel key="1" :header="inst.name || 'Instance'" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

                            <div class="img-alt-container mb-4">
                                <div>
                                    <!-- <div class="img-alt-box cursor-pointer" :style="{backgroundImage:`url(${form.url})`}" @click="selectMedia">
                                        <a-icon type="minus-circle" @click="removeImage(imgI)" />
                                    </div> -->
                                    <ImageBoxSelector v-model="inst.logo" :removable="true" />
                                </div>
                                
                                <p style="line-height:1" class="mt-2 mb-1">Choose instance to pull models</p>
                                <a-select v-model="inst.id" :options="availableInstances(inst.id)" />

                                <p style="line-height:1" class="mt-3 mb-1">Instance Label</p>
                                <a-input v-model="inst.name" />
                            </div>

                        <a-popconfirm
                            title="Are you sure delete this instance?"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="removeBlock(instI)"
                        >
                            <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                <a-icon type="minus-circle" /> Remove Instance
                            </a>
                        </a-popconfirm>
                        

                    </a-collapse-panel>

                </a-collapse>
                    
            </template>
        </div>
        <div v-else class="empty-blocks p-3" style="color:#ccc; font-size:14px; text-align:center;">
            No Instances Added
        </div>
        <template v-if="!limitReached">
            <div @click="addBlock" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                <a-icon type="plus-circle" />Add Instance
            </div>
        </template>
        <div v-else class="p-3 dF jC aC fC cursor-pointer no-selection text-center" style="border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
            
            <div class="dF aC" style="gap:1ch;">
                <a-icon type="stop" /> Instance limit reached
            </div> 
        </div>
    </div>
</template>

<script>
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    components:{ImageBoxSelector},
    props:{
        value:{
            type:Array,
            required:true,
            default: () => []
        },
    },
    data(){
        return {
            instanceLogos:[],
			instances:[]
        }
    },
    computed:{
        limitReached(){
            return this.instanceLogos.length >= this.instances.length
        },
        instances(){
            let {children = [],...instance} = this.$store.state.instance
            let options =  [instance,...children]
            return options.map( ({id,name}) => ({
                label:name,
                value:id
            }))
        },
    },
    methods:{
        availableInstances(currId){
            let currentInstances = []
            if (this.instanceLogos && this.instanceLogos.length){
                this.instanceLogos.forEach(x => {
                    if (x.id != currId) currentInstances.push(x.id)  
                })
            }
            return this.instances.filter(x => !currentInstances.includes(x.value))
        },
        addBlock(){
            let newBlock = {
                id:'',
                logo:'',
                name:''
            }
            if (this.limitReached){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
                this.instanceLogos.push(newBlock)
            }
        },
        removeBlock(i){
            this.instanceLogos.splice(i,1)
        },
    },
    watch:{
        instanceLogos:{
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        }
    },
    created(){
        this.$api.get(`/instances/:instance`).then( ({data}) => {
			let {children = [],...instance} = data
			let options = [instance,...children]
			options = options.map( ({id,name,api}) => ({
				label:name,
				value:api.key || id
			}))
			this.instances = options

			if (this.value){
				this.instanceLogos = this.value
			}
		})
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
        // if (this.value){
        //     console.log('CREATED THIS VALUE', this.value)
        //     this.instanceLogos = this.value
        // }
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>